<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <plantilla-articulo-aplicar-material-sistema-form
          :id="routeParams.idplantilla_articulo"
          :idsistema="routeParams.idsistema"
          @change-values="changeFormData"
        />
      </v-form>
    </b10-page-content>
  </b10-base>
</template>

<script>
import _ from '@/utils/lodash'
import { formPageMixin } from '@/mixins/formPageMixin'
import PlantillaArticuloAplicarMaterialSistemaForm from './components/PlantillaArticuloAplicarMaterialSistemaForm'
import Data from './PlantillaArticuloAplicarMaterialSistemaData'
import { encodeExtraQuery } from '@/utils/router'

export default {
  components: {
    PlantillaArticuloAplicarMaterialSistemaForm
  },
  mixins: [formPageMixin],
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    changeFormData (formData) {
      this.formData = formData
    },
    async loadPage () {
      const plantilla = await this.$offline.plantillaArticulo.row(this.routeParams.idplantilla_articulo)
      this.title = `Aplicar plantilla "${plantilla.plantilla_articulo.descripcion}"`
    },
    async submitForm () {
      const subsisTsubsis = await this.$offline.subsis.selectSubsisTsubsis(this.routeParams.idsistema)
      for (const fieldname in this.formData) {
        this.formData[fieldname].fields.idsubsis_idtsubsis = subsisTsubsis[this.formData[fieldname].fields.idsubsis_idtsubsis]
      }
      const idsMateriales = await Data.aplicarPlantillaArticulo(
        this, this.formData, this.routeParams.idsistema
      )
      const todosSubsis = _.map(await Data.selectSubsis(this, this.routeParams.idsistema), 'subsis.idsubsis')
      for (const subsis of todosSubsis) {
        await this.$dirty.modified(this.$dirty.ENTITIES.local.subsis, subsis)
      }
      this.$appRouter.replace({
        name: 'offline__material-sistema-edit',
        params: {
          idmaterial_sistema: idsMateriales[0],
        },
        query: {
          extra: encodeExtraQuery({ ids: idsMateriales }),
        },
      })
    },
  }
}
</script>
